import { useEffect, useState } from 'react'
import './Main.css';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import Duration from 'dayjs/plugin/duration'

dayjs.extend(AdvancedFormat)
dayjs.extend(Duration)

const TIME_SINCE = 1728600000000

const getTimeDuration = (timestamp) => {
  console.log("Calc", dayjs().toString())
  const date = dayjs(timestamp)
  const duration = dayjs.duration(dayjs() - date)
  const years = duration.years()
  const months = duration.months()
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()
  const time = [{ label: "years", time: years }, { label: "months", time: months }, { label: "days", time: days }, { label: "hours", time: hours }, { label: "minutes", time: minutes }, { label: "seconds", time: seconds }]

  const timeToString = time.reduce((currentString, timeFrame, index) => {
    const { time, label } = timeFrame;
    if (time > 0 || index > 1) {
      return `${currentString}${currentString ? ", " : ""}${time} ${label}`
    }
    return currentString
  }, "")

  return timeToString;
}

function App() {



  const [difference, setDifference] = useState(getTimeDuration(TIME_SINCE))

  useEffect(
    () => {
      {
        const run = setInterval(() => {
          setDifference(getTimeDuration(TIME_SINCE))
        }, 1000)

        return () => {
          clearInterval(run)
        }
      }
    }
    , [])

  return (
    <div className="App">
      <header className="App-header">
        <p>
          {"Time since " + dayjs(TIME_SINCE).format('ddd, MMM Do, h:mm A: ')}
        </p>
        <p className="timer">
          {difference}
        </p>
        <p className="timer">
          justblast
        </p>
      </header>
    </div>
  );
}

export default App;
